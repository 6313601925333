body, h1, h2, h3, h4, h5, h6, p, a, li {
	font-family: "Alegreya", sans-serif;
	font-size: 1.1rem;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
		margin-bottom: .5rem;
		color: #333;
}

p {
	line-height: 1.6rem;
}

h1 {
	letter-spacing: .01em;
	font-size: 55px;
	font-style: italic;
	line-height: 48px;
	margin-bottom: 74px;
	font-weight: 400;
	position: relative;
	@include respond-to('sm') {
		padding-bottom: 30px;
		font-size: map-get($font-sizes, xl);
		line-height: map-get($line-heights, xxl);
		margin-bottom: 30px;
	}
}

h2 {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2rem;
	margin-bottom: 20px;
	font-style: italic;

	&.font-size-xl {
		@include respond-to('sm') {
			font-size: map-get($font-sizes, l)
		}
	}
}

h3 {
	margin-bottom: 15px;
}

a {
	color: $primary;
	font-weight: bold;
	text-decoration: none;
	transition: all .12s ease-in-out;
}

p a {
	transition: 0.2s color;
	&:hover {
		color: $secondary;
	}
}