table {
	width: 100%;
	margin-bottom: 15px;
	&.one-third {
		td, th {
			width: 33%;
		}
	}
	tr {
		&:nth-child(even) {
			background: rgba(0,0,0,0.04);
		}
		td {
			padding-top: 2px;
			padding-bottom: 2px;
			&:first-child {
				padding-left: 8px;
			}
			&:last-child {
				padding-right: 5px;
			}
		}
	}

}