body.woocommerce div.product form.cart .gform_body ul li>div  select,
select {
	height: 40px;
	padding: 5px 15px;
	padding-right: 35px;
	border: 1px solid #333;
	border-radius: 1px;
	background: url('../../images/select-list-arrow.svg') no-repeat;
	background-position: right 10px top 12px;
	background-size: 15px auto;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.gform_body {
	ul {
		margin-bottom: 0;
		@extend .clearfix;
		padding: 0;
		li {
			list-style: none;
			float: left;
			width: 48%;
			clear: none !important;
			margin-bottom: 10px;

			@include respond-to('sm') {
				width: 100%;
			}
			
			&:nth-child(even){
				float: right;
			}
			input {
				width: calc(100% - 32px);
			}
			&.gform_validation_container {
				display: none;
			}

			input, textarea {
				padding: 5px 15px;
				border: 1px solid #a5a5a5;
				border-radius: 3px;
			}
			.gfield_label {
				font-weight: bold;
			}
			
		}
	}
}
.gform_wrapper .gform_footer {
	margin: 0 !important;
}

label {
	color: #333;
}