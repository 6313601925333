.page-home {
  .about {
    a.button {
      @include respond-to('sm') {
        margin-top: 30px;
        float: left !important;
      }
    }
  }
  .news {
    img {
      padding:50px 0;
    }

    // &> div > div:nth-child(odd) > img {
    //   padding-left: 0;
    // }
    // &> div > div:nth-child(even) > img{
    //   padding-right: 0;
    // }
  }
}

