
.text-over-image {
  padding: 190px 0;
  @include respond-to('md') {
    padding: 70px 0;
  }
  .image-container {
    position: relative;
    overflow: visible;
    @include respond-to('md') {
      padding: 0;
    }
    img {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      width: 130%;
      max-width: initial;
      @include respond-to('md') {
        top: 0;
        position: relative;
        transform: translateY(0);
        width: 100%;
      }
    }
  }
  .text-container {
    padding: 40px 60px;
    background: $gray-100;
  }
}