.font-size-xl {
  font-size: map-get($font-sizes, xxl);
  line-height: map-get($line-heights, xxl);
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.v-center {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}

.box-shadow {
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none !important;
}
.ml-s {
  margin-left: 6px;
}

.ml-m {
  margin-left: 12px;
}

.ml-l {
  margin-left: 18px;
}
.mt-s {
  margin-top: 6px;
}

.mt-m {
  margin-top: 12px;
}

.mt-l {
  margin-top: 18px;
}

.mt-xl {
  margin-top: 24px;
}

.mt-xxl {
  margin-top: 36px;
}

.mb-s {
  margin-bottom: 6px;
}

.mb-m {
  margin-bottom: 12px;
}

.mb-l {
  margin-bottom: 18px;
}

.mb-xl {
  margin-bottom: 24px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pt-s {
  padding-top: 6px;
}

.pt-m {
  padding-top: 12px;
}
.p-s {
  padding: 6px;
}
.p-l {
  padding: 12px;
}
.p-l {
  padding: 18px;
}

.pb-s {
  padding-bottom: 6px;
}

.pb-m {
  padding-bottom: 12px;
}

.pb-l {
  padding-bottom: 18px;
}

.pb-xl {
  padding-bottom: 24px;
}

.pb-xxl {
  padding-bottom: 30px;
}

.pb-xxxl {
  padding-bottom: 42px;
}

.pl-s {
  padding-left: 6px;
}

.pl-m {
  padding-left: 12px;
}

.pl-l {
  padding-left: 18px;
}

.pl-xl {
  padding-left: 24px;
}

.pl-xxl {
  padding-left: 30px;
}

.pl-xxxl {
  padding-left: 42px;
}

.pr-s {
  padding-right: 6px;
}

.pr-m {
  padding-right: 12px;
}

.pr-l {
  padding-right: 18px;
}

.pr-xl {
  padding-right: 24px;
}

.pr-xxl {
  padding-right: 30px;
}

.pr-xxxl {
  padding-right: 42px;
}

.pt-s {
  padding-top: 6px;
}

.pt-m {
  padding-top: 12px;
}

.pt-l {
  padding-top: 18px;
}

.pt-xl {
  padding-top: 24px;
}

.pt-xxl {
  padding-top: 36px;
}

.pt-xxxl {
  padding-top: 42px;
}

.pt-xxxxl {
  padding-top: 60px;
}

.row-padding {
  padding: $row-padding 0;
  @include respond-to('sm') {
    padding: $row-padding-sm 0;
  }
}

.row-padding-top {
  padding-top: $row-padding;
  @include respond-to('sm') {
    padding-top: $row-padding-sm 0;
  }
}

.row-padding-bottom {
  padding-bottom: $row-padding;
  @include respond-to('sm') {
    padding-bottom: $row-padding-sm 0;
  }
}