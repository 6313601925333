.img-split {
  img {
    width: calc(50% - 20px);
    margin-right: 15px;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}