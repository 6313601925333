.wrapper {
  // display: flex;
  width: 100%;
  // align-items: stretch;
}

.sidebar {
  width: 330px;
  min-height: 100vh;
  position: fixed;
}

.content {
  width: 100%;
  position: relative;
  margin-top: 45px;
}