
button {
	appearance: none;
	border: 0;
	background: transparent;
  padding: 0;
  
  &.close {
    width: 82px;
    height: 82px;
    will-change: transform;
    background: transparent;
    position: absolute;
    &:focus { 
      outline: none;
    }
    span {
      border-radius: 2px;
      text-indent: -900px;
      position: absolute;
      left: 24px;
      &:hover {
        cursor: pointer;
      }
      &:before, &:after {
        left: 0;
        display: inline-block;
        content: '';
        background: #333333;
        width: 34px;
        height: 4px;
        border-radius: 2px;
        position: absolute;
        transition: .3s all ease-in-out;
      }
      &:before {
        transform: rotate(315deg);
        top: 0;
      }
      &:after {
        transform: rotate(-315deg);
        top: 0;
      }
    }
  }
}

.button {
  font-family: "Roboto Mono";
	box-shadow: 0 0 9px rgba(0,0,0,0.1);
	font-size: 1rem;
	color: white;
	border: 0 !important;
	border-radius: 3px;
	padding: 21px 34px;
	transition: .15s all ease-in !important;
	text-transform: uppercase !important;
	font-weight: 600 !important;
	line-height: 20px;
	display: inline-block !important;
	background-color: $primary;
	&:hover {
    background-color: $secondary;
    color: $white;
    cursor: pointer;
  }
}

.button-secondary {
	@extend .button;
	font-size: 1rem;
  padding: 15px 20px;
  line-height: 1.6rem;
}

a.tel {
	padding-left: 32px;
	&:before {
		content: '';
		background: url('../../images/icon-phone.svg');
		height: 20px;
		width: 8px;
		display: inline-block;
		position: absolute;
		top: 13px;
		left: 20px;
	}
}

