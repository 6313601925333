.usp-list {
  li {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 20px;
      height: auto;
      margin-right: 5px;
    }
    p {
      display: inline-block;
      line-height: 26px;
      vertical-align: top;
    }
  }
}

.bullet-list {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  li {
    padding-left: 2px;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      background: url('../../images/checkmark.svg');
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 65%;
    }
  }
}