// Using $grid-breakpoints by Bootstrap _variables

@mixin respond-to($grid-breakpoint) {
  @if map-has-key($grid-breakpoints, $grid-breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $grid-breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

.clearfix {
	&:before, &:after {
		display: table;
	  	content: " ";
	}
	&:after {
		clear: both;
	}
}

$white: #ffffff;
$primary: #00ab67;
$secondary: #333333;
$row-padding: 70px;
$row-padding-sm: 30px;

$font-sizes: (
  s: 1rem,
  m: 1.2rem,
  l: 1.5rem,
  xl: 2.5rem,
  xxl: 3rem
);
$line-heights: (
  s: 1rem,
  m: 1.2rem,
  l: 1.5rem,
  xl: 2rem,
  xxl: 3rem
);

