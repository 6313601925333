header {
  .logo {
    max-width: 200px;
  }
  .facebook-icon {
    height: 37px;
    width: auto;
    transition: 0.2s fill;
    &:hover {
      fill: $primary;
    }
  }
}


header.fixed {
  position: fixed;
  height: 75px;
  .container-fluid {
    padding-top: 0;
  }
  .logo {
    margin-top: 0;
  }
}

header {
  height: 150px;
  position: relative;
  z-index: 999;
  top: 0;
  width: 100%;
  text-align: center;
  color: #333333;
  background-color: white;
  overflow: hidden;
  .container-fluid {
    top: calc(50% - 30px);
    position: relative;
    overflow: visible;
  }

  .cta {
    position: absolute;
    right: 18px;
    top: calc(50% - 21px);
    
    a {
      line-height: 42px;
      display: inline-block;
      vertical-align: top;
      font-size: 1.2rem;
      transition: 0.2s color;
      &:hover {
        color: $secondary;
      }
    }

    span {
      height: 42px;
      width: 1px;
      background: #dadada;
      display: inline-block;
      margin-left: 10px;
    }

    @include respond-to('sm') {
      display: none;
    }
  }
}