
nav .logo {
	height: 50px;
	margin-left: 40px;
	margin-top: 40px;
}

.nav-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 82px;
  height: 100%;
  will-change: transform;
  z-index: 9999;
  &:focus { 
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.nav-toggle span {
  position: absolute;
  background: #333333;
  width: 34px;
  height: 4px;
  border-radius: 2px;
  left: 20px;
  top: calc(50% - 2px);
  text-indent: -900px;
  :hover {
    cursor: default;
  }
  &:before, &:after {
    left: 0;
    display: inline-block;
    content: '';
    background: #333333;
    width: 34px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    top: -8px;
    transition: .3s all ease-in-out;
  }
  &:after {
    top: 8px;
  }
  @include respond-to('md') {
    display: inline-block;
  }

}

nav {
  ul {
		margin: 0;
		margin-top: 50px;
    padding: 0;
    li {
      list-style: none;
      font-size: 14px;
      
      a {
        color: #000;
        font-weight: normal;
				line-height: 44px;
				width: 100%;
				display: inline-block;
				padding-left: 40px;
				&:hover {
					color: $primary;
				}
      }
    }
  }
}

.st-container,
.st-pusher,
.st-content {
	height: 100%;
}

.st-content {
	overflow-y: scroll;
}

.st-content,
.st-content-inner {
	position: relative;
}

.st-container {
	position: relative;
}

.st-pusher {
	position: relative;
	left: 0;
	z-index: 99;
	min-height: 100vh;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.st-pusher::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-open .st-pusher::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	visibility: hidden;
	width: 300px;
	height: 100%;
  background: $white;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.st-menu::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu-open .st-menu::after {
	width: 0;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

/* Effect 4: Slide along */
.st-effect-4.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-4.st-menu {
	z-index: 1;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
}

.st-effect-4.st-menu-open .st-effect-4.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-4.st-menu::after {
	display: none;
}